const MAIN_PATHNAME = 'notarization-requests'
const SUB_PATHNAME = {
  LIST: 'list',
  OVERVIEW: 'overview',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  NEW: 'new',
  EDIT: 'edit',
}
const QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  NOTARIZATION_REQUESTS: 'notarization-requests',
  USER_LIST: 'user-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}
const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]
const PHONE_REGEX = /^(0)(3[2-9]|5[2689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/
const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/
export {MAIN_PATHNAME, SUB_PATHNAME, QUERIES, SPECIAL_KEYS, PHONE_REGEX, NAME_REGEX}
